import Layout from "@/layout";
import * as React from "react";
import {PrivacyPolicy} from "@/components/privacy/PrivacyPolicy";
import SEO from "@/components/seo";
import { useLocation } from '@reach/router';

const PP = () => {
    const {host} = useLocation();
    const canonical = `${host}/privacy-policy`;
  return (
    <Layout>
      <SEO title="PrivacyPolicy" canonical={canonical}/>

      <PrivacyPolicy />
    </Layout>
  );
};

export default PP;
